*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root
{
    --clr-primary : #B9F3D7;
    --bg-clr-nav: #7cf4ac;
    --bg-clr-form: rgb(180, 214, 224);
    --bg-clr-todos: #FBADAD;
    --bg-clr-todo: #EA2A2A;
    --bg-clr-footer: #000;
}
body
{
    position: relative;
    scroll-behavior: smooth;
    background: var(--clr-primary);
}
.App
{
    position: relative;
    min-height: 100vh;
}
div.App > nav.navbar
{
    border-color: rgba(199, 233, 11, 0.712) !important;
    background-color: var(--bg-clr-nav) !important;
}
nav.navbar .navbar-brand
{
    color: #0B36D2;
}

.model
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1111114d;
}
.close-model
{
    position: absolute;
    top: 0;
    right: 20px;
    border: none;
    outline: none;
    width: 30px;
    font-size: 28px;
    text-align: center;
    background: transparent;
}
#add-form
{
    /* display: none; */
    position: relative;
    width: 90%;
    max-width: 500px;
    border-radius: 5px;
    background-color: var(--bg-clr-form);
    box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 16%), 
        0px 2px 10px 0px rgb(0 0 0 / 12%);
}

div#add-form > form input[type='text' i],
div#add-form > form input[type='text' i]:is(:hover, :focus)
{
    outline: none !important;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    border-bottom: 1px solid #fff;
}
div#add-form > form button[type='submit' i]
{
    width: 85px;
    font-weight: bold;
    color: whitesmoke;
    background-color: #1656d4;
    letter-spacing: 2px !important;
    outline: none !important;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 5px 2px rgb(0 0 0 / 16%), 
        0px 2px 10px 0px rgb(0 0 0 / 12%);
}
i.bi-x
{
    font-size: 35px;
    font-weight: bold;
    color: rgb(248, 59, 59)!important;
}
.bi-x-circle-fill
{
    font-size: 30px;
    color: rgb(251, 105, 105);
}
.bi-plus-circle
{
    width: 30px!important;
    height: 30px!important;
    font-size: 30px;
    color: #fff;
}
button.show-form
{
    background-color: transparent;
    outline: none !important;
    border: none !important;
    border-radius: 0 !important;
}
button.clearTasks
{
    letter-spacing: .2em;
    border: none;
    color: rgb(246, 53, 53);
    background-color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-left: 10px;
}
button.show-form:focus
{
    outline: none !important;
    border: none !important;
}
form > button, 
form > button:hover,
form > button:is(:focus, :active)
{
    outline:none !important;
    border: none !important;
}

div.Todos.container
{    
    background-color: var(--bg-clr-todos);
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden    
}
div.Todos, div.Todo
{
    box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 16%), 
        0px 2px 10px 0px rgb(0 0 0 / 12%);
}
div.Todos > h2
{
    text-align: center;
    width: 70%;
    margin: 20px auto;
}
div.Todo
{
    width: 100%;
    min-height: 55px !important;
    opacity: 0;
    position: relative;
    background-color: var(--bg-clr-todo) !important;
    animation: fadeIn 0.8s ease-in-out both;
}

@keyframes fadeIn
{
    to {
        opacity: 1;
    }
}

div.todo-content
{
    width: calc( 100% - 55px );
    min-height: 55px !important;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
div.Todo div.card-header
{
    min-height: 55px !important;
    height: fit-content;
}

div.Todo div.card-header h4
{
    transition: 5ms ease-in;
}

.check
{
    display: block;
    position: relative;
    padding-left: 5px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.check input
{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 100;
}
.checkmark
{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: #dca;
    z-index: 99;
}
.check:hover input ~ .checkmark
{
    background-color: #ccc;
}
.check input:checked ~ .checkmark
{
    background-color: rgb(52, 160, 9);
}
.checkmark:after
{
    content: "";
    position: absolute;
    display: none;
}
.check input:checked ~ .checkmark:after
{
    display: block;
}
.check .checkmark:after
{
    left: 0.446em;
    top: .3em;
    width: 5px;
    height: 8px;
    border: 2px solid white;
    border-width: 0 2.5px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

div.Todo div.card-header h4
{
    width: calc( 100% - 25px );
    word-wrap: break-word;   
}

div.Todo > button.deleteBtn
{
    position: sticky;
    right: 0;
    height: auto;
    max-height: 55px !important;
    max-width: 55px;
    background-color: #283642 !important;
    transition: 1ms ease-in-out;
}
div.Todo > button.deleteBtn:hover i.bi-x
{
    font-size: 40px;
}
div.Todo > button.deleteBtn:focus
{
    opacity: .6 ;
    box-shadow: inset 2px 2px 2px rgb(27, 25, 25);
}
footer.footer 
{
    position: sticky;
    width: 100%;
    bottom: 0;
    z-Index: 99;
    background-color: var(--bg-clr-footer) !important;
}

/* ................................................................ */
/* Media Quary */
@media (min-width:568px)
{
    .App
    {
        max-width: 600px !important;
        width:75% !important;
    }
    .mx-0
    {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}

@media (max-width:567px)
{
    .App
    {
        margin: 0;
    }
    .container-sm
    {
        margin: 0 !important;
        padding: 0 !important;
    }
    #add-form
    {
        width: 90% !important;
    }
    div.Todos.container
    {
        max-height: calc(100vh - 150px);
    }
    footer.footer 
    {
        width: 100% !important;
    }
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
    width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
    background: rgba(233, 235, 122, 0.801);
    border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {    
    background: rgb(88, 228, 135);
    border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(35, 243, 105);
}/*...END...*/